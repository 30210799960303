import { useEffect, useState } from "react";

import Loader from "@components/Loader";
import Header from "@components/home/Header";
import Faq from "@components/home/Faq";
import Footer from "@components/home/Footer";
import Slider from "@components/home/Slider";

import homeContent from "@data/home/content";

const Home = () => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => setLoading(false), 1000);
  }, []);

  const isEven = (n) => {
    return n % 2 === 0;
  };

  // const isOdd = (n) => {
  //   return n % 2 !== 0;
  // };

  // const randomClients = homeContent.hero.clients.sort(
  //   () => Math.random() - Math.random()
  // ).slice(0, 3);

  return (
    loading
      ? <Loader theme="light" />
      :
      <>
        <Header />

        <section id="hero">
          <div className="container">
            <div id="hero-content">
              <div className="flex align-content-center justify-content-center">
                <h1>{homeContent.hero.title}</h1>
              </div>
              <div className="margin-top-48 margin-top-0-laptop">
                <div className="aurora" aria-hidden={true}></div>
              </div>
            </div>
            {/* <div id="hero-list">
              {randomClients.map((client, index) => (
                <figure key={index}>
                  <img src={client.src} alt={client.alt} />
                </figure>
              ))}
            </div> */}
          </div>
        </section>

        <section id="funcoes" data-section="features">
          <div className="device">
            <div className="device-widescreen">
              <Slider images={homeContent.about.images}/>
            </div>
          </div>
          <div className="container margin-top-40 margin-top-80-tablet">
            <div className="grid gap-32 gap-64-tablet columns-2-laptop align-center">
              <div className="column-span-1-laptop">
                <hgroup className="title">
                  <h2>{homeContent.about.title}</h2>
                </hgroup>
                <div className="margin-top-24">
                  <p>{homeContent.about.text}</p>
                </div>
                <div className="margin-top-48">
                  <a href={homeContent.about.ctaUrl} className="button button-large" target="_blank">
                    <i className="icon icon-brands-whatsapp icon-24 background-light margin-right-8" aria-hidden={true}></i>
                    <span>{homeContent.about.cta}</span>
                  </a>
                </div>
              </div>
              <div className="column-span-1-laptop">
                <div className="grid gap-16">
                  {homeContent.about.features.map((feature, index) => (
                    <div className="feature" key={index}>
                      <div>
                        <div className="feature-icon">
                          <i className={`icon icon-light-${feature.icon} icon-32`} aria-hidden={true}></i>
                        </div>
                      </div>
                      <div className="feature-content">
                        <h3>{feature.title}</h3>
                        <p className="margin-top-16">
                          {feature.text}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="sobre" className="margin-top-40 margin-top-80-tablet" data-section="about">
          <div className="container container-small">
            <div className="grid gap-24">
              {homeContent.about.content.map((item, index) => (
                <div className="grid gap-24 columns-2-tablet align-center" key={index}>
                  <figure className={isEven(index) ? "order-1-tablet margin-auto" : "margin-auto"}>
                    <img src={item.imgSrc} alt={item.imgAlt} />
                  </figure>
                  <div className="">
                    <hgroup className="title">
                      <h4>{item.title}</h4>
                    </hgroup>
                    <div className="margin-top-32">
                      {item.text.map((paragraph, index) => (
                        <p key={index}>{paragraph}</p>
                      ))}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>

        <section id="contratar" className="margin-top-40 margin-top-80-tablet" data-section="hire">
          <div className="container">
            <div className="grid columns-3-laptop gap-40 gap-0-laptop">
              <div className="order-0-laptop">
                <hgroup className="title text-center">
                  <h4>{homeContent.hire.title}</h4>
                </hgroup>
              </div>
              <div className="order-2-laptop justify-self-center">
                <hgroup className="content">
                  <h3>{homeContent.hire.contentIntro}</h3>
                  <h4>{homeContent.hire.contentEmphasis}</h4>
                </hgroup>
                <div className="flex justify-content-center margin-top-48">
                  <a href={homeContent.hire.ctaUrl} className="button button-large" target="_blank">
                    <i className="icon icon-brands-whatsapp icon-24 background-light margin-right-8" aria-hidden={true}></i>
                    <span>{homeContent.hire.cta}</span>
                  </a>
                </div>
              </div>
              <div className="order-1-laptop">
                <figure>
                  <img src={homeContent.hire.imgSrc} alt={homeContent.hire.imgAlt} />
                </figure>
              </div>
            </div>
          </div>
        </section>

        <section id="duvidas" className="margin-top-40 margin-top-80-tablet" data-section="faq">
          <div className="container container-small">
            <hgroup className="title">
              <h4>{homeContent.faq.title}</h4>
            </hgroup>
            <div className="margin-top-48">
              <Faq />
            </div>
          </div>
        </section>

        <Footer />
      </>
  );
};

export default Home;
