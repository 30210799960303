const faqContent = [
  {
    question: "Como os dados são carregados na Ampli?",
    answer: [
      "Os dados são atualizados por meio de rotas de API, estabelecendo assim uma conexão direta entre a plataforma Ampli e seu sistema ERP.",
    ],
  },
  {
    question: "Com que frequência meus dados são atualizados?",
    answer: [
      "Atualmente operamos com atualização mensal em data fixa definida de acordo com as medição física de acompanhamento, para que os comparativos entre Valor Pago e Valor Agregado sejam feitos na mesma janela de tempo.",
    ],
  },
  {
    question: "Como é calculado o IDP?",
    answer: [
      "O índice de desempenho de prazo é calcula a partir da razão entre os percentuais acumulados Medidos e  os percentuais previstos pela linha de Base. IDP = %Medido acumulado/ %Base acumulado. Ou seja se o IDP superior a 1 significa que a obra está em dia, pois todas as medições acumuladas representam um valor superior àqueles que o cronograma Base previa.",
    ],
  },
  {
    question: "Como é calculado o IDC?",
    answer: [
      "O índice de desempenho de custo é calculado a partir da razão entre o valor medido e valor pago. IDC = R$ Medido/ R$ Pago. Ou seja, um IDC superior a 1 significa que a obra está agregando mais valor (medindo) do que pagando. Um IDC de 1,50 significa que para cada real pago foi agregado R$1,50 a obra por meio das medições físicas.",
    ],
  },
  {
    question: "Quais custos compõem o Valor Total Comprometido?",
    answer: [
      "O valor total Comprometido é a soma de todos os valores pagos (saída de caixa) e a pagar (previsões de pedidos e contratos).",
    ],
  },
  {
    question: "Como é calculada a tendência da obra?",
    answer: [
      "A tendência procura identificar padrões para os custos incorridos e, a partir disso, projetar uma tendência de custo para a obra. É importante ressaltar que o cálculo é feito no nível de apropriação definido para a obra, ou seja, para chegar nos valor de tendência da obra é preciso somar todas as tendências calculadas nos níveis inferiores.",
      "Ex: se o nível de apropriação de uma obra é Serviço, o valor da tendência da obra é obtido como a soma das tendências de cada Serviço.",
      "No artigo elaborado pelo ERP Sienge podemos verificar as diferentes fórmulas de cálculo para a tendência, na Ampli é adotada a Fórmula 3.",
    ],
  },
  {
    question: "Como é calculado o Valor Orçado Indexado?",
    answer: [
      "O cálculo tem o objetivo fazer a correção do Valor Orçado Base no tempo. A correção é feita somando o Orçamento Base com a multiplicação do Orçamento Base pela variação de um indexador (por exemplo CUB) no período transcorrido entre o mês base do orçamento e o mês da última medição.",
    ],
  },
  {
    question: "O que seria a Verba disponível?",
    answer: [
      "O objetivo desse cálculo seria estimar uma verba disponível de acordo com o orçamento atual (indexado) e todo o valor que já foi comprometido. Ou seja, Verba disponível = Valor Orçado Indexado - Valor total comprometido.",
    ],
  },
  {
    question: "Como posso identificar insumos orçados para uma determinada etapa do meu orçamento?",
    answer: [
      "Na tela de valor agregado expandido a EAP orçamentária até o nível de Serviço e posteriormente clicando na aba de detalhe (disponível nos três pontos na extremidade direita da linha).",
    ],
  },
  {
    question: "Qual a diferença entre medição de contrato e medição física de acompanhamento de obra?",
    answer: [
      "O termo medição pode ser usado em contextos distintos dentro do meio da construção civil e é do ponto de vista do controle de obras é importante fazer distinção entre 1- Medição de contrato e 2 - Medição física de acompanhamento.",
    ],
    list: [
      {
        title: "1 - Medição de Contrato",
        content: "Refere-se ao pagamento de um valor já previsto levando em consideração o que foi executado pelo fornecedor associado ao contrato. É o processo de conversão de valor “a pagar” em valor “pago” para um determinado contrato.",
      },
      {
        title: "2 - Medição de Física de Acompanhamento",
        content: "Refere-se a atribuição de percentuais executados para as tarefas planejadas da obra. Por meio da vinculação de custos esses percentuais executados são associados aos valores orçados e assim chegamos no valor agregado (medido) para a obra após uma determinada medição física.",
      },
    ]
  },
];

export default faqContent;