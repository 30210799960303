import footerContent from "@data/footer/content";

const Footer = () => {
  return (
    <footer id="footer" className="margin-top-40 margin-top-80-tablet">
      <div className="container container-small">
        <div className="flex justify-content-center">
          <div>
            <h2 id="footer-title">
              {footerContent.content.label}
            </h2>
            <a id="footer-mailto" href={`mailto:${footerContent.content.email}`} target="_blank">
              {footerContent.content.email}
            </a>
          </div>
        </div>
        <div className="grid gap-16 justify-center margin-top-48">
          <nav className="flex gap-24">
            {footerContent.social.map((item, index) => (
              <a href={item.url} className="footer-social" target="_blank" key={index}>
                {item.name}
              </a>
            ))}
          </nav>
          <p id="footer-copyrights">
            {footerContent.content.copyrights}
          </p>
        </div>
      </div>
    </footer>
  );
}
export default Footer;