import React from "react";
import { useEffect, useState } from "react";
import ScrollSpy from 'react-scrollspy-navigation';

import headerContent from "@data/header/content";

const Header = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      const scrollThreshold = 80;
      setIsScrolled(scrollY > scrollThreshold);
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <header id="header" className={isScrolled ? 'active' : undefined} data-page="home">
      <div className="container">
        <div className="flex gap-24 justify-content-space-between align-center">
          <ScrollSpy duration={3000} offsetTop={120}>
            <a className="display-none" href="#hero" aria-hidden={true}></a>
            <a id="header-logo" href={headerContent.logo.to} ref={React.createRef()}>
              <img src={headerContent.logo.src} alt={headerContent.logo.alt} ref={React.createRef()} />
            </a>
          </ScrollSpy>
          <nav id="header-nav">
            <ScrollSpy duration={3000} offsetTop={120}>
              {headerContent.nav.map((link, index) => (
                <a href={link.to} ref={React.createRef()} key={index}>{link.label}</a>
              ))}
            </ScrollSpy>
          </nav>
          <a id="header-cta" href={headerContent.cta.to} target="_blank">
            {headerContent.cta.label}
          </a>
        </div>
      </div>
    </header>
  );
}
export default Header;