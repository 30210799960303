const logoAmpli = `${process.env.assetsDomain}/assets/images/logo-ampli.svg`;

const headerContent = {
  logo: {
    to: "#",
    src: logoAmpli,
    alt: "Ampli",
  },
  nav: [
    {
      to: "#funcoes",
      label: "Funções",
    },
    {
      to: "#sobre",
      label: "Sobre",
    },
    {
      to: "#contratar",
      label: "Contratar",
    },
    {
      to: "#duvidas",
      label: "Dúvidas",
    },
  ],
  cta: {
    to: "http://app.amplitecnologia.com",
    label: "Acesse sua conta",
  }
}

export default headerContent;