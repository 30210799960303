const logoAmpli = `${process.env.assetsDomain}/assets/images/logo-ampli.svg`;
const applicationLogin = `${process.env.assetsDomain}/assets/images/application-login.png`;
const applicationDashboard = `${process.env.assetsDomain}/assets/images/application-dashboard.png`;
const applicationValue = `${process.env.assetsDomain}/assets/images/application-value.png`;
const applicationProjection = `${process.env.assetsDomain}/assets/images/application-projection.png`;
const auroraPlatform = `${process.env.assetsDomain}/assets/images/aurora-platform.png`;
const auroraAutomation = `${process.env.assetsDomain}/assets/images/aurora-automation.png`;
const consultant = `${process.env.assetsDomain}/assets/images/consultant.png`;

const homeContent = {
  hero: {
    title: "ampliando visão & futuro",
    clients: [
      {
        src: logoAmpli,
        alt: "Cliente Um",
      },
      {
        src: logoAmpli,
        alt: "Cliente Dois",
      },
      {
        src: logoAmpli,
        alt: "Cliente Três",
      },
    ],
  },
  about: {
    title: "acompanhe suas obras de forma simples e dinâmica",
    text: "A Ampli tem como principal público-alvo construtoras e incorporadoras que possuem o objetivo de acompanhar suas obras de forma simplificada e dinâmica, permitindo tomadas de decisões mais rápidas e assertivas no mundo corporativo.",
    cta: "Contrate agora",
    ctaUrl: "https://api.whatsapp.com/send?phone=47992456794",
    images: [
      {
        figureSrc: applicationDashboard,
        figureAlt: "Ampli - Dashboard",
      },
      {
        figureSrc: applicationValue,
        figureAlt: "Ampli - Valor Agregado",
      },
      {
        figureSrc: applicationProjection,
        figureAlt: "Ampli - Projeção Financeira",
      },
      {
        figureSrc: applicationLogin,
        figureAlt: "Ampli - Login",
      },
    ],
    features: [
      {
        icon: "chart-line",
        title: "Detalhamento",
        text: "Tenha acesso rápido e visual às informações de cada uma de suas obras, controle os indicadores de tempo e custo e visualize de forma completa o avanço mensal previsto da obra.",
      },
      {
        icon: "coins",
        title: "Valor agregado",
        text: "Visualize o comparativo entre valores teóricos e reais além de análisar os Indicadores de Custo (IDC) em cada nível da EAP orçamentária desde o nível mais macro, entre diversos outros detalhamentos.",
      },
      {
        icon: "chart-bar",
        title: "Projeção financeira",
        text: "Faça o comparativo entre os desembolsos planejados e valores efetivamente pagos e visualize o gráfico de desembolsos planejados para cada mês dentro da EAP orçamentária.",
      },
    ],
    content: [
      {
        imgSrc: auroraPlatform,
        imgAlt: "Uma plataforma para tomadas de decisão",
        title: "Uma plataforma para tomadas de decisão",
        text: [
          "Nossa missão é ser a principal plataforma para tomada de decisões do mercado imobiliário e construção civil no território nacional.",
          "Transformamos a gestão tradicional da construção civil em uma gestão orientada a dados.",
        ],
      },
      {
        imgSrc: auroraAutomation,
        imgAlt: "Integração e automatização",
        title: "Integração e automatização",
        text: [
          "A Ampli fornece integração com o ERP Sienge, provendo a automatização do data pipeline, responsável pelo ETL completo dos dados da construtora.",
          "Tudo interligado por meio de uma aplicação com foco na camada de visualização dos dados, permitindo desenvolver potenciais insights que auxiliem no crescimento do setor de construção civil.",
        ],
      },
    ],
  },
  hire: {
    imgSrc: consultant,
    imgAlt: "Fale com um consultor",
    title: "Fale com o time de implantação",
    cta: "Solicite atendimento",
    ctaUrl: "https://api.whatsapp.com/send?phone=47992456794",
    contentIntro: "Receba a ligação de um consultor de",
    contentEmphasis: "ampliação e análise de dados",
  },
  faq: {
    title: "Perguntas frequentes",
  },
}

export default homeContent;