import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import ScrollToTop from "@components/ScrollToTop";

import App from "./App";

const isOnLambdaExecURL = (hostname) => {
  const words = hostname.split(".");
  return (
    words[1] === "execute-api" && words[3] === "amazonaws" && words[4] === "com"
  );
};

const getFirstPath = (pathname) => {
  const words = pathname.split("/");
  return `/${words[1]}`;
};

const location = window && window.location;

const basename =
  location && isOnLambdaExecURL(location.hostname)
    ? getFirstPath(location.pathname)
    : null;

const root = ReactDOM.createRoot(document.getElementById("app"));
root.render(
  <BrowserRouter>
    <ScrollToTop>
      <App />
    </ScrollToTop>
  </BrowserRouter>,
);