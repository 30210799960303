import { Routes, Route } from "react-router-dom";

import Helmet from "@components/Helmet";

import Home from "@pages/home/Index";

const App = () => {
  return (
    <>
      <Helmet />
      <Routes>
        <Route path="/" element={<Home />} />
      </Routes>
    </>
  );
};

export default App;