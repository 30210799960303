import { Fragment, useState } from "react";
import faqContent from "@data/faq/content";

const Faq = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleAccordionClick = (index) => {
    if (index === activeIndex) {
      setActiveIndex(null);
    } else {
      setActiveIndex(index);
    }
  };

  return (
    <div className="accordion">
      {faqContent.map((item, index) => (
        <Fragment key={index}>
          <button className={activeIndex === index ? "accordion-head active" : "accordion-head"} onClick={() => handleAccordionClick(index)}>
            <span>{item.question}</span>
          </button>
          <div className={activeIndex === index ? "accordion-panel active" : "accordion-panel"}>
            <div>
              {item.answer.map((paragraph, index) => (
                <p key={index}>{paragraph}</p>
              ))}
              {item.list?.map((listItem, index) => (
                <div className="grid margin-top-8 padding-left-24" key={index}>
                  <h4 className="text-bold">{listItem.title}</h4>
                  <p>{listItem.content}</p>
                </div>
              ))}
            </div>
          </div>
        </Fragment>
      ))}
    </div>
  )
}

export default Faq;