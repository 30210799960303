const copyStart = "© Ampli "
const copyYear = (new Date().getFullYear())
const copyEnd = " . Todos os direitos reservados."

const footerContent = {
  content: {
    label: "email",
    email: "hello@amplitecnologia.com",
    copyrights: copyStart + copyYear + copyEnd,
  },
  social: [
    {
      name: "Linkedin",
      url: "https://www.linkedin.com/company/ampli-tecnologia/",
    },
    {
      name: "Instagram",
      url: "https://www.instagram.com/ampli.tecnologia/",
    },
  ]
}

export default footerContent;