import { Helmet as ReactHelmet } from "react-helmet";

const Helmet = () => {
  return (
    <ReactHelmet>
      <meta charset="UTF-8"></meta>
      <meta httpEquiv="X-UA-Compatible" content="ie=edge" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta name="theme-color" content="#ffffff" />
      <meta name="msapplication-TileColor" content="#000000" />
      <meta name="msapplication-config" content={`${process.env.assetsDomain}/assets/images/favicon/browserconfig.xml`} />

      <title>Ampli Tecnologia</title>

      <link rel="manifest" href={`${process.env.assetsDomain}/assets/images/favicon/site.webmanifest`} />
      <link rel="mask-icon" href={`${process.env.assetsDomain}/assets/images/favicon/safari-pinned-tab.svg`} color="#5bbad5" />
      <link rel="apple-touch-icon" sizes="180x180" href={`${process.env.assetsDomain}assets/images/favicon/apple-touch-icon.png`} />
      <link rel="shortcut icon" type="image/x-icon" href={`${process.env.assetsDomain}/assets/images/favicon/favicon.ico`}></link>
      <link rel="icon" type="image/x-icon" sizes="16x16" href={`${process.env.assetsDomain}/assets/images/favicon/favicon-16x16.png`} />
      <link rel="icon" type="image/x-icon" sizes="32x32" href={`${process.env.assetsDomain}/assets/images/favicon/favicon-32x32.png`} />

      <link rel="stylesheet" href={`${process.env.assetsDomain}/assets/css/main.css`} />
    </ReactHelmet>
  );
};

export default Helmet;