import { useEffect, useState } from "react";

const Slider = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  return (
    <>
      {images.map((image, index) => (
        <img className={index === currentIndex ? "fade" : undefined} src={image.figureSrc} alt={image.figureAlt} key={index} />
      ))}
    </>
  );
};

export default Slider;